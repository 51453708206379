import { useMutation } from 'react-query';
import { clickToDial } from '../helpers/external/call';
import { useCallback, useMemo } from 'react';
import { decrypData } from '../util/crypto';
import { toast } from 'react-toastify';
import ClickToCallAlert from '../Components/Operation/Lead/ClickToCall/ClickToCallAlert';
import { useDispatch } from 'react-redux';
import { addMessage } from '../slices/messages/reducer';
import { useTranslation } from 'react-i18next';

const useCall = () => {
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'hook.useCall',
	});
	const { mutate, isLoading, isError, error, isSuccess } =
		useMutation(clickToDial);
	const dispatch = useDispatch();

	const makeCall = useCallback(
		(customer, phone) => {
			const decryptedData = decrypData(
				localStorage.getItem('authenticatication-crm')
			);
			const user = JSON.parse(decryptedData);
			const data = {
				customer: parseInt(customer),
				phone: phone,
				userName: user.usuario,
				provider: user.providerExt,
				extension: user.extension,
				server: user.server?.value,
			};
			const isValid =
				Boolean(user.providerExt) &&
				Boolean(user.extension) &&
				Boolean(user.server?.value);
			if (isValid) {
				toast(<ClickToCallAlert user={user} />, {
					autoClose: 6000,
					position: 'top-center',
				});
				mutate(data);
			} else {
				dispatch(
					addMessage({
						type: 'error',
						message: tMessage('validateCall'),
					})
				);
			}
		},
		[mutate, dispatch, tMessage]
	);

	return useMemo(
		() => ({
			makeCall,
		}),
		[makeCall]
	);
};

export default useCall;
