import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';
import { Button, Col, Input, Row } from 'reactstrap';

export default function TablePaginate({ columns, data }) {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.paginationManual',
	});
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		nextPage,
		previousPage,
		state: { pageIndex },
	} = useTable(
		{ columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
		usePagination
	);
	return (
		<Row>
			<Col>
				<div className="table-rep-plugin">
					<div className="table-responsive">
						<table
							{...getTableProps()}
							className="table align-middle table-bordered table-hover responsiveTable"
						>
							<thead className="table-light">
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th
												{...column.getHeaderProps({
													style: column.style || {},
												})}
											>
												{column.render('Header')}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{rows.length > 0 ? (
									page.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return (
														<td
															{...cell.getCellProps()}
														>
															{cell.render(
																'Cell'
															)}
														</td>
													);
												})}
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan={columns.length}>
											No hay información disponible
										</td>
									</tr>
								)}
							</tbody>
						</table>
						<div className="d-flex align-items-center justify-content-end p-1 fs-7">
							<div className="me-2">
								<Button
									color="primary"
									size="sm"
									onClick={() => previousPage()}
									disabled={!canPreviousPage}
								>
									{'<'}
								</Button>
							</div>
							<div className="me-2">
								{t('page')}{' '}
								<strong>
									{pageIndex + 1} {t('from')}{' '}
									{pageOptions.length}
								</strong>
							</div>
							<div className="me-2">
								<Input
									type="number"
									min={1}
									style={{ width: 70 }}
									max={pageOptions.length}
									value={pageIndex + 1}
									onChange={(e) => gotoPage(e.target.value)}
									size="sm"
								/>
							</div>
							<div className="me-2">
								<Button
									color="primary"
									size="sm"
									onClick={() => nextPage()}
									disabled={!canNextPage}
								>
									{'>'}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
}
