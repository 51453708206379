import { Card, CardBody, CardHeader } from 'reactstrap';
import Loader from '../../Common/Loader';
import jsFormatNumber from '../../../util/jsFormatNumber';
import { useTranslation } from 'react-i18next';

const TableRevenuePerAgent = ({ items, isSearching }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.revenuePerAgent.tableRevenuePerAgent',
	});
	return (
		<Card>
			<CardHeader className="align-items-center d-flex">
				<h4 className="card-title mb-0 flex-grow-1">{t('agents')}</h4>
			</CardHeader>
			<CardBody>
				{isSearching ? (
					<Loader />
				) : (
					<div className="table-responsive table-card">
						<table className="table table-borderless table-hover table-nowrap align-middle mb-0">
							<thead className="table-light">
								<tr className="text-muted">
									<th scope="col">{t('name')}</th>
									<th scope="col" style={{ width: '20%' }}>
										{t('income')} (USD)
									</th>
									<th scope="col" style={{ width: '20%' }}>
										%
									</th>
								</tr>
							</thead>

							<tbody>
								{items.length === 0 ? (
									<tr>
										<td>{t('noDataAvailable')}</td>
									</tr>
								) : (
									items.map((item, index) => (
										<tr key={index}>
											<td>
												<div className="position-relative d-inline-block">
													<div className="avatar-xs rounded-circle img-thumbnail">
														<div className="avatar-title bg-soft-success text-success rounded-circle fs-6">
															{item.user.charAt(
																0
															)}
														</div>
													</div>
												</div>
												<span
													to="#"
													className="text-body fw-medium ms-2"
												>
													{item.user}
												</span>
											</td>
											<td>
												<div className="text-nowrap">
													{jsFormatNumber(
														item.totalUSD
													)}
												</div>
											</td>
											<td>
												<div className="text-nowrap">
													{item.percentage}
												</div>
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default TableRevenuePerAgent;
