import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableRevenuePerAgent from '../../../Components/Report/RevenuePerAgent/TableRevenuePerAgent';
import ChartRevenuePerAgent from '../../../Components/Report/RevenuePerAgent/ChartRevenuePerAgent';
import ChartRevenuePerHotel from '../../../Components/Report/RevenuePerAgent/ChartRevenuePerHotel';
import RevenuePerAgentSearch from '../../../Components/Report/RevenuePerAgent/RevenuePerAgentSearch';
import { useMutation } from 'react-query';
import { revenueAnalytics } from '../../../helpers/report';
import { useMemo, useCallback, useState } from 'react';
import { ERROR_SERVER } from '../../../Components/constants/messages';
import extractMeaningfulMessage from '../../../util/extractMeaningfulMessage';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../slices/messages/reducer';
import ChartRevenuePerCallCenter from '../../../Components/Report/RevenuePerAgent/ChartRevenuePerCallCenter';
import ResumeWidgets from '../../../Components/Charts/ResumeWidgets';
import ReservationCanvas from '../../../Components/Common/ReservationCanvas';

const RevenuePerAgent = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.revenuePerAgent',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	document.title = t('header');
	const dispatch = useDispatch();
	const [filter, setFilter] = useState(null);
	const [showCanva, setShowCanva] = useState(false);

	const {
		mutate: mutateReport,
		isLoading: isSearching,
		data: itemsData,
	} = useMutation(revenueAnalytics, {
		onError: (error) => {
			let message = tMessage(ERROR_SERVER);
			message = extractMeaningfulMessage(error, message);
			dispatch(
				addMessage({
					type: 'error',
					message: message,
				})
			);
		},
	});

	const getMainInfo = useCallback((data) => {
		const arr = [];
		arr.push({
			id: 1,
			label: 'Revenue',
			badge: 'ri-arrow-up-circle-line text-success',
			icon: 'ri-money-dollar-circle-line',
			counter: data.total,
			decimals: 2,
			suffix: '',
			prefix: '$',
			onClick: () => {},
		});
		if (data.users.length > 0) {
			arr.push({
				id: 2,
				label: `Top Agent`,
				subLabel: data.users[0].user ?? 'Otro',
				subLabelIcon: 'ri-user-fill',
				badge: 'ri-arrow-up-circle-line text-success',
				icon: 'ri-money-dollar-circle-line',
				counter: data.users[0].totalUSD,
				decimals: 2,
				suffix: '',
				prefix: '$',
				onClick: () => undefined,
			});
		}
		if (data.hotels.length > 0) {
			arr.push({
				id: 3,
				label: `Top Hotel`,
				subLabel: data.hotels[0].hotel ?? 'Otro',
				subLabelIcon: 'ri-hotel-fill',
				badge: 'ri-arrow-up-circle-line text-success',
				icon: 'ri-money-dollar-circle-line',
				counter: data.hotels[0].totalUSD,
				decimals: 2,
				suffix: '',
				prefix: '$',
				onClick: () => undefined,
			});
		}
		if (data.callCenters.length > 0) {
			arr.push({
				id: 4,
				label: `Top Call Center`,
				subLabel: data.callCenters[0].callCenter ?? 'Otro',
				subLabelIcon: 'bx bxs-phone-call',
				badge: 'ri-arrow-up-circle-line text-success',
				icon: 'ri-money-dollar-circle-line',
				counter: data.callCenters[0].totalUSD,
				decimals: 2,
				suffix: '',
				prefix: '$',
				onClick: undefined,
			});
		}

		return arr;
	}, []);

	const { agents, hotels, callCenters, crmWidgets } = useMemo(() => {
		if (itemsData !== undefined) {
			return {
				agents: itemsData.users,
				hotels: itemsData.hotels,
				callCenters: itemsData.callCenters,
				crmWidgets: getMainInfo(itemsData),
			};
		}
		return {
			agents: [],
			hotels: [],
			callCenters: [],
			crmWidgets: [],
		};
	}, [itemsData, getMainInfo]);

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb
					title={t('title')}
					pageTitle={t('revenue')}
					urlPageTitle="/revenue"
				/>

				<Row>
					<Col>
						<Card className="shadow">
							<CardBody className="pt-0">
								<div className="py-3">
									<RevenuePerAgentSearch
										handleSubmit={mutateReport}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				{crmWidgets.length > 0 && (
					<Row>
						<ResumeWidgets crmWidgets={crmWidgets} />
					</Row>
				)}

				<Row>
					<Col xs={12} lg={3}>
						<ChartRevenuePerHotel
							items={hotels}
							isSearching={isSearching}
						/>
					</Col>
					<Col xs={12} lg={3}>
						<ChartRevenuePerCallCenter
							items={callCenters}
							isSearching={isSearching}
						/>
					</Col>
					<Col xs={12} lg={6}>
						<ChartRevenuePerAgent
							items={agents}
							isSearching={isSearching}
						/>
					</Col>
					<Col xs={12} lg={12}>
						<TableRevenuePerAgent
							items={agents}
							isSearching={isSearching}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default RevenuePerAgent;
