import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { serviceIncomeAnalytics } from '../../../helpers/report';
import useNotifications from '../../../hooks/useNotifications';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import ReservationProductionSearch from '../../../Components/Report/ReservationProduction/ReservationProductionSearch';
import Loader from '../../../Components/Common/Loader';
import TableTotalIncome from '../../../Components/Report/ReservationProduction/TableTotalIncome';
import TableReservationTotal from '../../../Components/Report/ReservationProduction/TableReservationTotal';

const ReservationProduction = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.reservationProduction',
	});
	document.title = t('header');
	const notification = useNotifications();
	const {
		mutate: mutateReport,
		isLoading: isSearching,
		data: itemsData,
	} = useMutation(serviceIncomeAnalytics, {
		onError: (error) => {
			notification.error(error);
		},
	});

	const onHandleSubmit = (body) => {
		mutateReport(body);
	};

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb
					title={t('title')}
					pageTitle={t('title')}
					urlPageTitle="/serviceIncome"
				/>
				<Row>
					<Col>
						<Card className="shadow">
							<CardBody className="pt-0">
								<div className="py-3">
									<ReservationProductionSearch
										handleSubmit={onHandleSubmit}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				{isSearching && <Loader />}

				{!isSearching && (
					<Row>
						<Col xs="12" md="6">
							<TableTotalIncome payments={itemsData?.payments} />
						</Col>
						<Col xs="12" md="6">
							<TableReservationTotal
								reservations={itemsData?.reservations}
							/>
						</Col>
					</Row>
				)}
			</Container>
		</div>
	);
};

export default ReservationProduction;
