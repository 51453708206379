import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import parseObjectToQueryUrl from '../../../../util/parseObjectToQueryUrl';
import { getDepartmentPaginate } from '../../../../helpers/catalogues/departments';
import { useQuery } from 'react-query';
import { addMessage } from '../../../../slices/messages/reducer';
import showFriendlyMessafe from '../../../../util/showFriendlyMessafe';
import { editIconClass } from '../../../../Components/constants/icons';
import CellActions from '../../../../Components/Common/CellActions';
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import CardHeaderGlobal from '../../../../Components/Common/CardHeaderGlobal';
import TableContainer from '../../../../Components/Common/TableContainer';
import PaginationManual from '../../../../Components/Common/PaginationManual';
import Loader from '../../../../Components/Common/Loader';
import BasicModal from '../../../../Components/Common/BasicModal';
import FormDepartment from '../../../../Components/Configuration/Catalogue/Department/FormDepartment';

const initFilter = {
	name: '',
};

const Department = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.department.list',
	});
	document.title = t('header');
	const dispatch = useDispatch();
	const [item, setItem] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [parameter, setParameter] = useState('');
	const [query, setQuery] = useState({
		max: 10,
		page: 1,
		...initFilter,
	});
	const [queryFilter, setQueryFilter] = useState(
		parseObjectToQueryUrl(query)
	);
	//query to get list
	const {
		data: itemsData,
		error: errorItemsQuery,
		isLoading,
		isSuccess,
		refetch,
	} = useQuery(
		['getDepartmentPaginate', queryFilter],
		() => getDepartmentPaginate(queryFilter),
		{
			keepPreviousData: true,
		}
	);

	useEffect(() => {
		if (errorItemsQuery?.code) {
			dispatch(
				addMessage({
					message: showFriendlyMessafe(errorItemsQuery?.code),
					type: 'error',
				})
			);
		}
	}, [errorItemsQuery, dispatch]);

	const buscar = () => {
		const copyQuery = { ...query, page: 1, name: parameter };
		setQueryFilter(parseObjectToQueryUrl(copyQuery));
		setQuery(copyQuery);
	};

	const editItem = (row) => {
		const { original } = row;
		setItem({
			id: original.id ?? '',
			name: original.name ?? '',
			callcenters: original?.callcenters ?? '',
			clave: original?.clave ?? '',
			contexto: original?.contexto ?? '',
			email: original?.email ?? '',
			generapoliza: original?.generapoliza ?? '',
		});
		setShowModal(true);
	};

	const columns = useMemo(
		() => [
			{
				Header: t('name'),
				accessor: 'name',
				filterable: false,
				style: {
					width: '20%',
				},
			},
			{
				Header: t('context'),
				accessor: 'contexto',
				filterable: false,
				style: {
					width: '20%',
				},
			},
			{
				Header: t('key'),
				accessor: 'clave',
				filterable: false,
				style: {
					width: '10%',
				},
			},
			{
				Header: t('email'),
				accessor: 'email',
				filterable: false,
				style: {
					width: '20%',
				},
			},
			{
				Header: 'Call center',
				accessor: 'callcenters',
				filterable: false,
				style: {
					width: '20%',
				},
			},
			{
				Header: t('generatesPolicy'),
				accessor: 'generapoliza',
				filterable: false,
				style: {
					width: '10%',
				},
				Cell: ({ value }) => (
					<span className="fw-bold text-center">
						{value === 0 ? t('no') : t('yes')}
					</span>
				),
			},

			{
				id: 'action',
				style: {
					width: '5%',
				},
				Cell: ({ row }) => {
					return (
						<CellActions
							actions={[
								{
									iconClass: `${editIconClass} fs-5 text-primary`,
									click: editItem,
									labelTooltip: t('edit'),
								},
							]}
							row={row}
						/>
					);
				},
			},
		],
		[t]
	);

	const toggleDialog = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col xxl={12}>
							<Card className="shadow">
								<CardHeaderGlobal title={t('department')} />
								<CardBody className="pt-0">
									<div className="py-3 d-flex justify-content-end border border-dashed border-end-0 border-start-0">
										<div className="position-relative">
											<Input
												type="text"
												className="form-control me-1"
												placeholder={`${t(
													'search'
												)}...`}
												id="search-options"
												value={parameter}
												onChange={(e) =>
													setParameter(e.target.value)
												}
											/>
										</div>
										<button
											className="btn btn-info ms-1"
											onClick={buscar}
										>
											<i className="mdi mdi-magnify"></i>{' '}
											{t('search')}
										</button>
									</div>
									<div>
										{!isLoading ? (
											<>
												<TableContainer
													columns={columns}
													data={
														isSuccess
															? itemsData?.data
																	?.list ?? []
															: []
													}
													className="custom-header-css"
													divClass="table-responsive mb-3"
													tableClass="align-middle table-nowrap"
													theadClass=""
												/>
												<PaginationManual
													query={query}
													setQuery={setQuery}
													setQueryFilter={
														setQueryFilter
													}
													totalPages={
														itemsData?.data
															?.pagination
															?.totalPages ?? 1
													}
													showTotal={true}
													totalCount={
														itemsData?.data
															?.pagination
															?.totalCount ?? 0
													}
												/>
											</>
										) : (
											<Loader />
										)}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
			<BasicModal
				open={showModal}
				setOpen={setShowModal}
				title={t('editDepartment')}
				size="lg"
				children={
					<FormDepartment
						item={item}
						toggleModal={toggleDialog}
						refetch={refetch}
					/>
				}
			/>
		</>
	);
};

export default Department;
