import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Row,
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import ArrivalsCoupleSearch from '../../../Components/Report/ArrivalCouple/ArrivalsCoupleSearch';
import { useMutation } from 'react-query';
import { arrivalsCoupleAnalytics } from '../../../helpers/report';
import useNotifications from '../../../hooks/useNotifications';
import Loader from '../../../Components/Common/Loader';
import TableCoupleArrivals from '../../../Components/Report/ArrivalCouple/TableCoupleArrivals';
import moment from 'moment';
import TooltipDescription from '../../../Components/Common/TooltipDescription';
import { infoIconClass } from '../../../Components/constants/icons';

const getColByItems = (count) => {
	switch (count) {
		case 3:
			return '4';
		case 2:
			return '6';
		default:
			return '12';
	}
};

const getYear = (year, count) => {
	if (count === 1) return year;
	return year - count + 1;
};

const ArrivalsCouple = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.arrivalCouple',
	});
	document.title = t('header');
	const notification = useNotifications();
	const [filter, setFilter] = useState(null);
	const [year, setYear] = useState(null);
	const [count, setCount] = useState(1);
	const [itemsToCompare, setItemsToCompare] = useState([]);
	const [showByCallCenter, setShowByCallCenter] = useState(true);

	const {
		mutate: mutateReport,
		isLoading: isSearching,
		data: itemsData,
	} = useMutation(arrivalsCoupleAnalytics, {
		onError: (error) => {
			notification.error(error);
		},
	});
	const onHandleSubmit = (body) => {
		setFilter(body);
		setCount(1);
		setYear(
			parseInt(moment(body.dateRange.start, 'YYYY-MM-DD').format('YYYY'))
		);
		mutateReport(body);
	};

	useEffect(() => {
		if (itemsData !== undefined) {
			const newObj = {
				title: getYear(year, count),
				data: itemsData,
			};
			if (count === 1) {
				setItemsToCompare([newObj]);
			} else {
				const copy = [...itemsToCompare, newObj].sort(
					(a, b) => a.title - b.title
				);
				setItemsToCompare(copy);
			}
		}
	}, [itemsData, year]);

	const handleAddCount = () => {
		setCount((prev) => (prev += 1));
		const newStartDate = moment(filter.dateRange.start).subtract(
			count,
			'years'
		);
		const newEndDate = moment(filter.dateRange.end).subtract(
			count,
			'years'
		);
		const copyFilter = { ...filter };
		copyFilter.dateRange = {
			start: moment(newStartDate).format('YYYY-MM-DD'),
			end: moment(newEndDate).format('YYYY-MM-DD'),
		};
		mutateReport(copyFilter);
	};

	const handleRestCount = () => {
		setCount((prev) => (prev -= 1));
		const copyItems = [...itemsToCompare];
		copyItems.splice(0, 1);
		setItemsToCompare(copyItems);
	};

	console.log(itemsToCompare);

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb
					title={t('title')}
					pageTitle={t('title')}
					urlPageTitle="/arrivalsCouple"
				/>

				<Row>
					<Col>
						<Card className="shadow">
							<CardBody className="pt-0">
								<div className="py-3">
									<ArrivalsCoupleSearch
										handleSubmit={onHandleSubmit}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>

				{isSearching && <Loader />}

				{itemsToCompare.length > 0 && !isSearching && (
					<Row>
						<Col xs="12" md="12">
							<div className="d-flex justify-content-end align-items-center mb-2">
								<div className="d-flex flex-1">
									<div className="form-check me-4">
										<Input
											className="form-check-input"
											type="radio"
											id="showByCallCenter"
											name="groupBy"
											checked={showByCallCenter}
											onChange={(evt) =>
												setShowByCallCenter(
													evt.target.checked
												)
											}
										/>
										<Label
											className="form-check-label"
											htmlFor="showByCallCenter"
										>
											{t('showByCallCenter')}
										</Label>
									</div>
									<div className="form-check">
										<Input
											className="form-check-input"
											type="radio"
											id="showBySegment"
											name="groupBy"
											checked={!showByCallCenter}
											onChange={(evt) =>
												setShowByCallCenter(
													!evt.target.checked
												)
											}
										/>
										<Label
											className="form-check-label"
											htmlFor="showBySegment"
										>
											{t('showBySegment')}
										</Label>
									</div>
								</div>
								<div className="align-items-center d-flex justify-content-end me-2">
									<span className="me-2">
										{t('addOtherYear')}{' '}
									</span>
									<i
										id="label-compare"
										className={`${infoIconClass} text-primary fs-5`}
									/>
									<TooltipDescription
										text={t('addOtherYearHelp')}
										id={'label-compare'}
									/>
								</div>
								<div className="d-flex">
									<Button
										color="primary"
										type="button"
										size="sm"
										className="rounded-0 rounded-start"
										disabled={count === 1}
										onClick={handleRestCount}
									>
										-
									</Button>
									<div className="form-control rounded-0">
										{count}
									</div>

									<Button
										color="primary"
										type="button"
										size="sm"
										className="rounded-0 rounded-end"
										disabled={count === 3}
										onClick={handleAddCount}
									>
										+
									</Button>
								</div>
							</div>
						</Col>
						{itemsToCompare.map((item, index) => (
							<Col xs="12" md={getColByItems(count)}>
								{itemsToCompare.length > 1 && (
									<Alert
										color="info"
										className="text-center m-0"
									>
										{item.title}
									</Alert>
								)}
								<TableCoupleArrivals
									itemsToCompare={itemsToCompare}
									index={index}
									items={item}
									showByCallCenter={showByCallCenter}
								/>
							</Col>
						))}
					</Row>
				)}
			</Container>
		</div>
	);
};

export default ArrivalsCouple;
