import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import jsFormatNumber from '../../../util/jsFormatNumber';
import { Card, CardBody, Col, Row } from 'reactstrap';
import TableContainer from '../../Common/TableContainer';
import WidgetTotalIncome from './WidgetTotalIncome';

const TableTotalIncome = ({ payments }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.reservationProduction',
	});
	const columns = useMemo(
		() => [
			{
				Header: t('service'),
				accessor: 'service',
				filterable: false,
				style: {
					width: '50%',
				},
			},
			{
				Header: t('total'),
				accessor: 'total',
				filterable: false,
				style: {
					width: '30%',
				},
				Cell: ({ value }) => jsFormatNumber(value),
			},
			{
				Header: '%',
				accessor: 'percentage',
				filterable: false,
				style: {
					width: '20%',
				},
			},
		],
		[t]
	);

	if (payments === undefined) return null;

	return (
		<Card className="shadow-sm">
			<CardBody>
				<h2 className="mb-3">{t('serviceIncome')}</h2>
				<Row>
					<Col>
						<WidgetTotalIncome
							total={payments?.total}
							label={'Total'}
							iconClass="ri ri-money-dollar-circle-line text-success"
							spanClass="avatar-title bg-soft-success text-success rounded-2 fs-2"
							prefix="$"
						/>
					</Col>
				</Row>
				<TableContainer
					columns={columns}
					data={payments?.list ?? []}
					className="custom-header-css"
					divClass="table-responsive mb-3"
					tableClass="align-middle"
					theadClass="table-light"
				/>
			</CardBody>
		</Card>
	);
};

export default TableTotalIncome;
