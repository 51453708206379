import CountUp from 'react-countup';

const WidgetTotalIncome = ({
	total,
	iconClass,
	label,
	spanClass,
	prefix = '',
}) => {
	return (
		<div className="d-flex align-items-center">
			<div className="avatar-sm flex-shrink-0">
				<span className={spanClass}>
					<i className={iconClass} />
				</span>
			</div>
			<div className="flex-grow-1 overflow-hidden ms-4">
				<div
					className="d-flex align-items-center"
					style={{ gap: '4px' }}
				>
					<div className="me-4">
						<p className="text-uppercase fw-medium text-muted text-truncate mb-3">
							{label}
						</p>
						<div className="d-flex align-items-center mb-3">
							<h4 className="fs-4 flex-grow-1 mb-0">
								<span
									className="counter-value me-1"
									data-target="825"
								>
									<CountUp
										start={0}
										suffix={''}
										prefix={prefix}
										separator={','}
										end={total}
										duration={4}
									/>
								</span>
							</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WidgetTotalIncome;
