import { useEffect, useState } from 'react';
import { DropdownItem, Input } from 'reactstrap';
import { editIconClass } from '../constants/icons';
import useUser from '../../hooks/useUser';
import { encryptData } from '../../util/crypto';
import { getDataAgent } from '../../util/getDataAgent';
import { useQuery } from 'react-query';
import { getServerList } from '../../helpers/external/call';
import { useTranslation } from 'react-i18next';
import { SELECT_OPTION } from '../constants/messages';

const ProfileServer = () => {
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const [toggleServer, setToggleServer] = useState(false);
	const [isHover, setHover] = useState(false);
	const [server, setServer] = useState(null);
	const [serverInput, setServerInput] = useState('');
	const [providerInput, setProviderInput] = useState('');
	const user = useUser();

	const { data: serverOpt } = useQuery(
		['getServerList'],
		async () => {
			const response = await getServerList();
			return response;
		},
		{
			select: (response) =>
				response.data.serversList.map((it) => ({
					value: it.key,
					label: it.value,
				})),
		}
	);

	useEffect(() => {
		if (user) {
			setServer(getDataAgent(user, 'server'));
			setServerInput(getDataAgent(user, 'server')?.value ?? '');
			setProviderInput(getDataAgent(user, 'providerExt') ?? '');
		}
	}, [user]);

	const updateServer = () => {
		if (!Boolean(serverInput) || !Boolean(providerInput)) {
			return;
		} else {
			//copy del object
			const copyUser = { ...user };
			const fullServer = serverOpt.find((it) => it.value === serverInput);
			copyUser.server = fullServer;
			copyUser.providerExt = providerInput;
			setServer(fullServer);
			//encrypt data again
			const encryptedData = encryptData(JSON.stringify(copyUser));
			localStorage.setItem('authenticatication-crm', encryptedData);
			setToggleServer(false);
		}
	};

	return (
		<DropdownItem className="p-0" text>
			{!toggleServer && (
				<div
					className="dropdown-item d-flex justify-content-between align-items-center"
					onMouseOver={() => setHover(true)}
					onMouseOut={() => setHover(false)}
					onClick={() => setToggleServer(true)}
				>
					<div>
						<i className="mdi mdi-server-network text-muted fs-16 align-middle me-1" />
						<span className="align-middle">
							{server
								? `${providerInput} - ${server.label}`
								: '-'}
						</span>
					</div>
					{isHover && (
						<div>
							<i
								className={`${editIconClass} fs-5 text-primary`}
							/>
						</div>
					)}
				</div>
			)}
			{toggleServer && (
				<div className="dropdown-item d-flex align-items-center">
					<i className="mdi mdi-server-network text-muted fs-16 align-middle me-1" />
					<div className="input-group">
						<Input
							size={'sm'}
							type="select"
							value={providerInput}
							onChange={(e) => setProviderInput(e.target.value)}
						>
							<option value="">{tMessage(SELECT_OPTION)}</option>
							{[...Array(9)].map((_, i) => (
								<option key={i + 1} value={i + 1}>
									{i + 1}
								</option>
							))}
						</Input>
						<Input
							size={'sm'}
							type="select"
							style={{ flexGrow: 3 }}
							value={serverInput}
							onChange={(e) => setServerInput(e.target.value)}
						>
							<option value="">{tMessage(SELECT_OPTION)}</option>
							{serverOpt.map((item) => (
								<option key={item.value} value={item.value}>
									{item.label}
								</option>
							))}
						</Input>
						<button
							className="btn btn-success btn-sm"
							type="button"
							onClick={updateServer}
						>
							<i className="ri-check-line" />
						</button>
						<button
							className="btn btn-danger btn-sm"
							type="button"
							onClick={() => {
								setToggleServer(false);
							}}
						>
							<i className="ri-close-line" />
						</button>
					</div>
				</div>
			)}
		</DropdownItem>
	);
};

export default ProfileServer;
