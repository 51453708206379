import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = ({ options, series, height = 267.7, dir = 'ltr' }) => {
	return (
		<React.Fragment>
			<ReactApexChart
				dir={dir}
				className="apex-charts"
				options={options}
				series={series}
				type={'pie'}
				height={height}
			/>
		</React.Fragment>
	);
};

export default PieChart;
