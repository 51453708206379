import GlobalCanvas from './GlobalCanvas';
import ReservationList from '../Operation/Reservation/ReservationList';
const ReservationCanvas = ({ filter, show, onCloseClick }) => {
	return (
		<GlobalCanvas
			show={show}
			onCloseClick={onCloseClick}
			title={'Reservaciones'}
			children={<ReservationList filter={filter} enabledQuery={show} />}
			fullWidth={true}
		/>
	);
};

export default ReservationCanvas;
