import { Card, CardBody, CardHeader } from 'reactstrap';
import PieChart from '../../Chart/PieChart';
import { useTranslation } from 'react-i18next';
import Loader from '../../Common/Loader';

const ChartArrivalsByCallCenter = ({ items, isSearching }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.arrivals.chartArrivalsByCallCenter',
	});
	const series = items.map((it) => it.total);
	const options = {
		chart: {
			height: 300,
			type: 'pie',
		},
		labels: items.map((it) => it.callCenter ?? 'Otro'),
		theme: {
			monochrome: {
				enabled: true,
				color: '#405189',
				shadeTo: 'light',
				shadeIntensity: 0.6,
			},
		},
		plotOptions: {
			pie: {
				dataLabels: {
					offset: -8,
				},
			},
		},
		dataLabels: {
			formatter: function (val, opts) {
				return val;
			},
			dropShadow: {
				enabled: false,
			},
		},
		legend: {
			show: false,
		},
		tooltip: {
			theme: 'dark',
			x: {
				show: false,
			},
			y: {
				formatter: function (value) {
					return value;
				},
				title: {
					formatter: function (seriesName) {
						return `${seriesName}:`;
					},
				},
			},
		},
	};
	return (
		<Card className="card-height-100">
			<CardHeader className="align-items-center d-flex">
				<h4 className="card-title mb-0 flex-grow-1">
					{t('arrivalsByCallCenter')}
				</h4>
			</CardHeader>
			<CardBody className="px-0">
				{isSearching ? (
					<Loader />
				) : items.length === 0 ? (
					<p className="px-2">{t('noDataAvailable')}</p>
				) : (
					<PieChart series={series} options={options} />
				)}
			</CardBody>
		</Card>
	);
};

export default ChartArrivalsByCallCenter;
