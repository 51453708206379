import { post } from './api_helper';
import * as url from './url';

const revenueAnalytics = (data) => post(`${url.report}/revenueAnalytics`, data);
const arrivalsAnalytics = (data) => post(`${url.report}/arrivals`, data);
const arrivalsCoupleAnalytics = (data) =>
	post(`${url.report}/arrivalsCouples`, data);
const serviceIncomeAnalytics = (data) => post(`${url.report}/production`, data);

export {
	revenueAnalytics,
	arrivalsAnalytics,
	arrivalsCoupleAnalytics,
	serviceIncomeAnalytics,
};
