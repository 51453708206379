import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TableContainer from '../../Common/TableContainer';
import { Card, CardBody, Col, Row } from 'reactstrap';
import WidgetArrivalCouple from './WidgetArrivalCouple';

const getColor = (value, index, name, itemsToCompare, showByCallCenter) => {
	const itemsOrder = [...itemsToCompare.sort((a, b) => a.title - b.title)];
	const listToCompare = showByCallCenter
		? itemsOrder[index - 1].data.callCenters
		: itemsOrder[index - 1].data.segments;
	const percentage =
		listToCompare.find((it) => it.name === name)?.percentage ?? undefined;
	if (percentage === undefined) return 'text-primary';
	return percentage === value
		? 'text-muted'
		: percentage < value
		? 'text-success'
		: 'text-danger';
};

const getIcon = (value, index, name, itemsToCompare, showByCallCenter) => {
	const itemsOrder = [...itemsToCompare.sort((a, b) => a.title - b.title)];
	const listToCompare = showByCallCenter
		? itemsOrder[index - 1].data.callCenters
		: itemsOrder[index - 1].data.segments;
	const percentage =
		listToCompare.find((it) => it.name === name)?.percentage ?? undefined;
	if (percentage === undefined) return '';
	return percentage === value
		? 'las la-equals'
		: percentage < value
		? 'bx bx-caret-up'
		: 'bx bx-caret-down';
};

const getPercentageDiff = (
	index,
	currentValue,
	itemsToCompare,
	showByCallCenter,
	name
) => {
	if (index === 0) return currentValue;
	const itemsOrder = [...itemsToCompare.sort((a, b) => a.title - b.title)];
	const listToCompare = showByCallCenter
		? itemsOrder[index - 1].data.callCenters
		: itemsOrder[index - 1].data.segments;
	const valueToCompare =
		listToCompare.find((it) => it.name === name)?.percentage ?? undefined;
	if (valueToCompare === undefined) return `${currentValue} %`;
	return `${Math.abs(currentValue - valueToCompare).toFixed(2)} %`;
};

const TableCoupleArrivals = ({
	items,
	itemsToCompare,
	index,
	showByCallCenter,
}) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.arrivalCouple',
	});

	const columns = useMemo(
		() => [
			{
				Header: t('titleTable'),
				accessor: 'name',
				filterable: false,
				style: {
					width: '45%',
				},
			},
			{
				Header: t('totalReservations'),
				accessor: 'totalReservations',
				filterable: false,
				style: {
					width: '20%',
				},
			},
			{
				Header: t('totalCouples'),
				accessor: 'totalCouples',
				filterable: false,
				style: {
					width: '15%',
				},
			},
			{
				Header: '%',
				accessor: 'percentage',
				filterable: false,
				style: {
					width: '20%',
				},
				Cell: ({ row, value }) => {
					if (index === 0) return value;
					return (
						<div
							className={`${getColor(
								value,
								index,
								row.original.name,
								itemsToCompare,
								showByCallCenter
							)} d-flex align-items-center`}
							style={{ gap: '2px' }}
						>
							<i
								className={getIcon(
									value,
									index,
									row.original.name,
									itemsToCompare,
									showByCallCenter
								)}
							/>
							{getPercentageDiff(
								index,
								value,
								itemsToCompare,
								showByCallCenter,
								row.original.name
							)}
						</div>
					);
				},
			},
		],
		[t, itemsToCompare, index, showByCallCenter]
	);

	return (
		<Card className="shadow-sm">
			<CardBody>
				<Row>
					<Col>
						<WidgetArrivalCouple
							totals={items?.data?.totals}
							itemsToCompare={itemsToCompare}
							index={index}
						/>
					</Col>
				</Row>
				<TableContainer
					columns={columns}
					data={
						showByCallCenter
							? items?.data?.callCenters
							: items?.data?.segments
					}
					className="custom-header-css"
					divClass="table-responsive mb-3"
					tableClass="align-middle"
					theadClass="table-light"
				/>
			</CardBody>
		</Card>
	);
};

export default TableCoupleArrivals;
