import { useDispatch } from 'react-redux';
import { ERROR_SERVER } from '../Components/constants/messages';
import { useCallback, useMemo } from 'react';
import { addMessage } from '../slices/messages/reducer';
import extractMeaningfulMessage from '../util/extractMeaningfulMessage';

const useNotifications = () => {
	const dispatch = useDispatch();

	const success = useCallback(
		(message) => {
			dispatch(
				addMessage({
					type: 'success',
					message: message,
				})
			);
		},
		[dispatch]
	);

	const error = useCallback(
		(message) => {
			let mess = extractMeaningfulMessage(message, ERROR_SERVER);
			dispatch(
				addMessage({
					type: 'error',
					message: mess,
				})
			);
		},
		[dispatch]
	);

	return useMemo(
		() => ({
			success,
			error,
		}),
		[success, error]
	);
};

export default useNotifications;
