import { Card, CardBody, CardHeader } from 'reactstrap';
import BarChart from '../../Chart/BarChart';
import { useTranslation } from 'react-i18next';
import Loader from '../../Common/Loader';

const ChartArrivalsByAgent = ({ items, isSearching }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.arrivals.chartArrivalsByAgent',
	});
	const series = [
		{
			data: items.map((it) => it.total),
		},
	];
	var options = {
		chart: {
			type: 'bar',
			height: 350,
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				barHeight: '100%',
				distributed: true,
				horizontal: true,
				dataLabels: {
					position: 'bottom',
				},
			},
		},
		dataLabels: {
			enabled: true,
			textAnchor: 'start',
			style: {
				colors: ['#000'],
				fontSize: '10px',
			},
			formatter: function (val, opt) {
				return opt.w.globals.labels[opt.dataPointIndex] + ': ' + val;
			},
			offsetX: 0,
			dropShadow: {
				enabled: false,
			},
		},
		stroke: {
			width: 1,
			colors: ['#fff'],
		},
		legend: {
			show: false,
		},
		xaxis: {
			categories: items.map((it) => it.user),
		},
		yaxis: {
			labels: {
				show: false,
			},
		},
		tooltip: {
			theme: 'light',
			x: {
				show: false,
			},
			y: {
				title: {
					formatter: function () {
						return 'Reservaciones:';
					},
				},
			},
		},
	};
	return (
		<Card className="card-height-100">
			<CardHeader className="align-items-center d-flex">
				<h4 className="card-title mb-0 flex-grow-1">
					{t('agentArrivals')}
				</h4>
			</CardHeader>
			<CardBody className="px-0">
				{isSearching ? (
					<Loader />
				) : items.length === 0 ? (
					<p className="px-2">{t('noDataAvailable')}</p>
				) : (
					<BarChart series={series} options={options} />
				)}
			</CardBody>
		</Card>
	);
};

export default ChartArrivalsByAgent;
