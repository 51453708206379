import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const getBadgeClass = (index, totals, itemsToCompare) => {
	if (index === 0) return 'badge-soft-light text-muted';
	const totalsToCompare = itemsToCompare[index - 1]?.data?.totals ?? {};

	if (totals.percentage > totalsToCompare.percentage) {
		return 'badge-soft-success';
	} else if (totals.percentage === totalsToCompare.percentage) {
		return 'badge-soft-light text-muted';
	} else {
		return 'badge-soft-danger';
	}
};

const getIconClass = (index, totals, itemsToCompare) => {
	if (index === 0) return '';
	const totalsToCompare = itemsToCompare[index - 1]?.data?.totals ?? {};
	if (totals.percentage > totalsToCompare.percentage) {
		return 'ri-arrow-up-s-line';
	} else if (totals.percentage === totalsToCompare.percentage) {
		return '';
	} else {
		return 'ri-arrow-down-s-line';
	}
};

const getPercentageDiff = (index, totals, itemsToCompare) => {
	if (index === 0) return totals.percentage;
	const totalsToCompare = itemsToCompare[index - 1]?.data?.totals ?? {};
	return `${Math.abs(totals.percentage - totalsToCompare.percentage).toFixed(
		2
	)} %`;
};

const WidgetArrivalCouple = ({ totals, itemsToCompare, index }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.arrivalCouple',
	});
	return (
		<div className="d-flex align-items-center">
			<div className="avatar-sm flex-shrink-0">
				<span
					className={`avatar-title bg-soft-primary text-primary rounded-2 fs-2`}
				>
					<i className="ri ri-hotel-line text-primary" />
				</span>
			</div>
			<div className="flex-grow-1 overflow-hidden ms-4">
				<div
					className="d-flex align-items-center"
					style={{ gap: '4px' }}
				>
					<div className="me-4">
						<p className="text-uppercase fw-medium text-muted text-truncate mb-3">
							{t('totalCheckIn')}
						</p>
						<div className="d-flex align-items-center mb-3">
							<h4 className="fs-4 flex-grow-1 mb-0">
								<span
									className="counter-value me-1"
									data-target="825"
								>
									<CountUp
										start={0}
										suffix={''}
										separator={','}
										end={totals?.totalSummary ?? 0}
										duration={4}
									/>
								</span>
							</h4>
						</div>
					</div>
					<div className="me-4">
						<p className="text-uppercase fw-medium text-muted text-truncate mb-3">
							{t('tCouples')}
						</p>
						<div className="d-flex align-items-center mb-3">
							<h4 className="fs-4 flex-grow-1 mb-0">
								<span
									className="counter-value me-1"
									data-target="825"
								>
									<CountUp
										start={0}
										suffix={''}
										separator={','}
										end={totals?.totalSummaryCouples ?? 0}
										duration={4}
									/>
								</span>
							</h4>
						</div>
					</div>
					<div>
						<span
							className={`fs-12 badge ${getBadgeClass(
								index,
								totals,
								itemsToCompare
							)}`}
						>
							<i
								className={`fs-13 align-middle me-1 ${getIconClass(
									index,
									totals,
									itemsToCompare
								)}`}
							></i>
							{getPercentageDiff(index, totals, itemsToCompare)}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WidgetArrivalCouple;
