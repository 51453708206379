import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({
	options,
	series,
	height = 350,
	type = 'bar',
	dir = 'ltr',
}) => {
	return (
		<React.Fragment>
			<ReactApexChart
				dir={dir}
				className="apex-charts"
				options={options}
				series={series}
				type={type}
				height={height}
			/>
		</React.Fragment>
	);
};

export default BarChart;
