import { useMemo } from 'react';
import { decrypData } from '../util/crypto';

const useUser = () => {
	const encode = localStorage.getItem('authenticatication-crm');

	const user = useMemo(() => {
		if (encode) {
			const decryptedData = decrypData(
				localStorage.getItem('authenticatication-crm')
			);
			const obj = JSON.parse(decryptedData);
			return obj;
		}
	}, [encode]);

	return user;
};

export default useUser;
