import { Button } from 'reactstrap';
import ButtonsLoader from '../Loader/ButtonsLoader';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const ButtonForm = ({
	textMain = null,
	type = 'submit',
	textSecond = null,
	cancelAction = null,
	okAction = null,
	isLoader = false,
}) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.buttonForm',
	});

	const textPrimary = useMemo(() => {
		if (!textMain) return t('ok');
		else return textMain;
	}, [textMain, t]);

	const textSecondary = useMemo(() => {
		if (!textSecond) return t('cancel');
		else return textSecond;
	}, [textSecond, t]);
	return (
		<>
			{isLoader ? (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: textPrimary,
								color: 'success',
								className: 'me-2',
								loader: true,
							},
							{
								text: textSecondary,
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			) : (
				<div className="d-flex my-3">
					{type === 'submit' ? (
						<Button type={type} color="success" className="me-2">
							{textPrimary}
						</Button>
					) : (
						<Button
							type="button"
							color="success"
							className="me-2"
							onClick={okAction ? okAction : () => {}}
						>
							{textPrimary}
						</Button>
					)}
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={cancelAction ? cancelAction : () => {}}
					>
						{textSecondary}
					</Button>
				</div>
			)}
		</>
	);
};

export default ButtonForm;
