import { Col, Row } from 'reactstrap';
import HTMLRender from '../../../../Common/HTMLRender';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
	previewConfirmLetter,
	sendConfirmLetter,
} from '../../../../../helpers/reservation';
import {
	ERROR_SERVER,
	SEND_CONFIRMATION_LETTER_SUCCESS,
} from '../../../../constants/messages';
import extractMeaningfulMessage from '../../../../../util/extractMeaningfulMessage';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../../../slices/messages/reducer';
import useUser from '../../../../../hooks/useUser';
import { useEffect } from 'react';
import Loader from '../../../../Common/Loader';
import ButtonForm from '../../../../Common/ButtonForm';

const LetterConfirmationDialog = ({ cancelAction, idReservation }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.letterConfirmationDialog',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const dispatch = useDispatch();
	const user = useUser();

	const {
		mutate: mutatePreviewConfirmationLetter,
		isLoading,
		data,
	} = useMutation(previewConfirmLetter, {
		onError: (error) => {
			let message = tMessage(ERROR_SERVER);
			message = extractMeaningfulMessage(error, message);
			dispatch(
				addMessage({
					type: 'error',
					message: message,
				})
			);
		},
	});

	useEffect(() => {
		if (idReservation) {
			const body = {
				idReservation: idReservation,
				username: user.usuario,
			};
			mutatePreviewConfirmationLetter(body);
		}
	}, [idReservation, user.usuario, mutatePreviewConfirmationLetter]);

	const { mutate: mutateConfirmationLetter, isLoading: isSendLetter } =
		useMutation(sendConfirmLetter, {
			onSuccess: () => {
				dispatch(
					addMessage({
						type: 'success',
						message: tMessage(SEND_CONFIRMATION_LETTER_SUCCESS),
					})
				);
				cancelAction();
			},
			onError: (error) => {
				let message = tMessage(ERROR_SERVER);
				message = extractMeaningfulMessage(error, message);
				dispatch(
					addMessage({
						type: 'error',
						message: message,
					})
				);
			},
		});

	const sendConfirmationLetter = () => {
		const body = {
			idReservation: idReservation,
			username: user.usuario,
		};
		mutateConfirmationLetter(body);
	};

	if (isLoading) {
		return (
			<div className="d-flex justify-content-center py-5">
				<Loader />
			</div>
		);
	}

	return (
		<Row>
			<Col xs="12" md="12">
				<HTMLRender html={data} />
			</Col>
			<Col xs="12" md="12">
				<ButtonForm
					cancelAction={cancelAction}
					isLoader={isSendLetter}
					textMain={t('send')}
					type="button"
					okAction={sendConfirmationLetter}
				/>
			</Col>
		</Row>
	);
};

export default LetterConfirmationDialog;
