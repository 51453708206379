import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import WidgetTotalIncome from './WidgetTotalIncome';

const getTotal = (array) => {
	return array.reduce((acc, curr) => acc + curr, 0);
};
const getPercentage = (list, item) => {
	const percentage = list
		.filter((it) => it.calification === item)
		.reduce((acc, curr) => acc + curr.percentage, 0);
	return percentage;
};

const getTotalColumn = (idx, rows) => {
	// Initialize sum
	let sum = 0;
	Object.values(rows).forEach((array) => {
		sum += array[idx];
	});
	return sum;
};

const TableReservationTotal = ({ reservations }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.reservationProduction',
	});

	if (reservations === undefined) return null;

	const programs = new Set();
	reservations.list.forEach((item) => {
		item.programs.forEach((program) => {
			programs.add(program.program);
		});
	});

	const programsArray = Array.from(programs);

	// Create table headers
	const tableHeaders = [...programsArray];

	// Initialize table rows
	const tableRows = {};

	reservations.list.forEach((item) => {
		if (!tableRows[item.calification]) {
			tableRows[item.calification] = new Array(programsArray.length).fill(
				0
			);
		}
		item.programs.forEach((program) => {
			const index = programsArray.indexOf(program.program);
			tableRows[item.calification][index] += program.total;
		});
	});

	return (
		<Card>
			<CardBody>
				<h2 className="mb-3">{t('reservationProduction')}</h2>
				<Row>
					<Col>
						<WidgetTotalIncome
							total={reservations?.total}
							label={t('reservationTotals')}
							iconClass="ri ri-hotel-line text-primary"
							spanClass="avatar-title bg-soft-primary text-primary rounded-2 fs-2"
						/>
					</Col>
				</Row>
				{reservations?.total > 0 && (
					<Table className="align-middle fs-7 table table-hover">
						<thead className="table-light">
							<tr>
								<th></th>
								{tableHeaders.map((item, idx) => (
									<th key={`thead-${idx}`}>{item}</th>
								))}
								<th>{t('total')}</th>
								<th>{t('percentageLetter')}</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(tableRows).map((item, idx) => (
								<tr key={`tbody-${idx}`}>
									<td className="fw-bold text-center">
										{item}
									</td>
									{tableRows[item].map((child, idxChild) => (
										<td
											key={`tbody-child-${idxChild}`}
											className="text-center"
										>
											{child}
										</td>
									))}
									<td className="text-center">
										{getTotal(tableRows[item])}
									</td>
									<td className="text-center">
										{getPercentage(reservations.list, item)}
										%
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<th className="fw-bold text-center text-uppercase">
									{t('totals')}
								</th>
								{tableRows[Object.keys(tableRows)[0]]?.map(
									(child, idxChild) => (
										<th
											key={`tbody-child-${idxChild}`}
											className="text-center"
										>
											{getTotalColumn(
												idxChild,
												tableRows
											)}
										</th>
									)
								)}
								<th className="text-center">
									{reservations?.total}
								</th>
								<th className="text-center">100%</th>
							</tr>
						</tfoot>
					</Table>
				)}
			</CardBody>
		</Card>
	);
};

export default TableReservationTotal;
