import {
	Accordion,
	AccordionItem,
	Button,
	Col,
	Collapse,
	Form,
	FormFeedback,
	Input,
	Label,
	Row,
} from 'reactstrap';
import Select from 'react-select';
import { Country } from 'country-state-city';
import { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useUser from '../../../../hooks/useUser';
import {
	ASSIGN_CLIENTS_SUCCESS,
	ERROR_SERVER,
	FIELD_INTEGER,
	FIELD_NUMERIC,
	FIELD_POSITIVE,
	FIELD_REQUIRED,
	FIELD_TOP_30,
	ONE_OPTION_REQUIRED,
	SELECT_OPTION,
} from '../../../constants/messages';
import ButtonsLoader from '../../../Loader/ButtonsLoader';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	assignClientsRandom,
	getAgentsBySupervisor,
	listCustomerMultiplesParams,
} from '../../../../helpers/customer';
import useRole from '../../../../hooks/useRole';
import Loader from '../../../Common/Loader';
import { addMessage } from '../../../../slices/messages/reducer';
import extractMeaningfulMessage from '../../../../util/extractMeaningfulMessage';
import { infoIconClass } from '../../../constants/icons';
import TooltipDescription from '../../../Common/TooltipDescription';
import { getCallCenterByUser } from '../../../../helpers/catalogues/call_center';
import DatePicker from '../../../Common/DatePicker';
import classNames from 'classnames';
import { getListEmotionsClients } from '../../../../helpers/notes';
import { getReservationStatusAll } from '../../../../helpers/catalogues/reservation_status';
import { getCampaingPaginate } from '../../../../helpers/catalogues/campaign';
import SelectAsync from '../../../Common/SelectAsync';
import moment from 'moment';
import TablePaginate from '../../../Common/TablePaginate';

const FormClientAssignment = ({ toggleDialog }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.operation.FormClientAssignment',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const user = useUser();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const { isAgent } = useRole();
	const [includeObj, setIncludeObj] = useState({
		salesAgents: [],
		salesStatus: [],
		statusNotes: [],
		campaings: [],
		callCenters: [],
		countries: [],
	});
	const [excludeObj, setExcludeObj] = useState({
		salesAgents: [],
		salesStatus: [],
		statusNotes: [],
		campaings: [],
		callCenters: [],
		countries: [],
	});
	const [agents, setAgents] = useState(null);
	const [date, setDate] = useState([]);
	const [isInclude, setIsInclude] = useState(false);
	const [isExclude, setIsExclude] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: t('name'),
				accessor: 'nombre',
				filterable: false,
				style: {
					width: '40%',
				},
				Cell: (contact) => (
					<>
						<div className="d-flex align-items-center">
							<div className="flex-shrink-0">
								<div className="flex-shrink-0 avatar-xs me-2">
									<div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
										{contact.row.original.firstName.charAt(
											0
										)}
									</div>
								</div>
							</div>
							<div className="flex-grow-1 ms-2 name">
								{`${contact.row.original.firstName} ${
									contact.row.original.lastName ?? ''
								}`}
							</div>
						</div>
					</>
				),
			},
			{
				Header: t('country'),
				accessor: 'country.name_esp',
				filterable: false,
				style: {
					width: '20%',
				},
			},
			{
				Header: t('assignedAgent'),
				accessor: 'userName',
				filterable: false,
				style: {
					width: '40%',
				},
			},
		],
		[t]
	);

	//getCallCenter
	const { data: callCenterOpt } = useQuery(
		['getCallCenterByUser'],
		() => getCallCenterByUser(user?.usuario),
		{
			enabled: user?.usuario !== undefined,
			select: (data) =>
				data.data?.list.map((item) => ({
					value: item.id,
					label: item.name,
				})) ?? [],
		}
	);

	//agents by super/manager
	const { data: agentsOpt } = useQuery(
		['getAgentsBySupervisor', user.usuario],
		() => getAgentsBySupervisor(user.usuario),
		{
			enabled: user !== null && !isAgent,
			select: (result) =>
				result.data.list.map((it) => ({
					value: it.id,
					label: it.id,
				})) ?? [],
		}
	);

	//notes status
	const { data: emotionsClientsTypeOpt } = useQuery(
		['getListEmotionsClients'],
		async () => {
			const response = await getListEmotionsClients();
			return response;
		},
		{
			select: (response) =>
				response.data.list
					.map((it) => ({
						key: parseInt(it.key),
						value: it.id,
						label: it.mood,
						labelEn: it.moodEng,
					}))
					.sort((a, b) => a.key - b.key),
		}
	);

	//sales status
	const { data: saleStatusOpt } = useQuery(
		['getReservationStatusAll'],
		async () => {
			const response = await getReservationStatusAll();
			return response;
		},
		{
			select: (response) =>
				response.data.list.map((it) => ({
					value: it.id,
					label: it.status,
					labelEn: it.statusEng,
				})),
		}
	);

	const { mutate: assignClient, isLoading: isAssigning } = useMutation(
		assignClientsRandom,
		{
			onSuccess: (result) => {
				queryClient.refetchQueries({
					queryKey: ['getCustomerPaginate'],
				});
				toggleDialog();
				dispatch(
					addMessage({
						type: 'success',
						message: tMessage(ASSIGN_CLIENTS_SUCCESS),
					})
				);
			},
			onError: (error) => {
				let message = tMessage(ERROR_SERVER);
				message = extractMeaningfulMessage(error, message);
				dispatch(
					addMessage({
						type: 'error',
						message: message,
					})
				);
			},
		}
	);

	const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			dateRange: {
				start: '',
				end: '',
			},
			agents: [],
			includes: {
				chargeBack: false,
				pendingBalance: false,
				salesStatus: [],
				statusNotes: [],
				campaings: [],
				callCenters: [],
				countries: [],
				salesAgents: [],
				lastNoteCreated: 0,
			},
			excludes: {
				chargeBack: false,
				pendingBalance: false,
				salesStatus: [],
				statusNotes: [],
				campaings: [],
				callCenters: [],
				countries: [],
				salesAgents: [],
				lastNoteCreated: 0,
			},
		},
		validationSchema: Yup.object({
			agents: Yup.array().min(1, tMessage(ONE_OPTION_REQUIRED)),
			dateRange: Yup.object().shape({
				end: Yup.string().required(tMessage(FIELD_REQUIRED)),
			}),
			includes: Yup.object().shape({
				lastNoteCreated: Yup.number()
					.min(0, tMessage(FIELD_POSITIVE))
					.max(30, tMessage(FIELD_TOP_30))
					.integer(tMessage(FIELD_INTEGER))
					.typeError(tMessage(FIELD_NUMERIC)),
			}),
			excludes: Yup.object().shape({
				lastNoteCreated: Yup.number()
					.min(0, tMessage(FIELD_POSITIVE))
					.max(30, tMessage(FIELD_TOP_30))
					.integer(tMessage(FIELD_INTEGER))
					.typeError(tMessage(FIELD_NUMERIC)),
			}),
		}),
		onSubmit: async (values) => {
			const body = {
				...values,
				userName: user?.usuario,
			};
			assignClient(body);
		},
	});

	const {
		mutate: mutateListCustomer,
		isLoading: isSearching,
		data: itemsData,
	} = useMutation(listCustomerMultiplesParams, {
		onError: (error) => {
			console.log('------error-------');
			console.log(error);
		},
	});

	const search = () => {
		const body = {
			...formik.values,
			userName: user?.usuario,
		};
		const isNotValid =
			formik.values.agents.length === 0 ||
			formik.values.dateRange.end.length === 0;
		if (isNotValid) {
			formik.submitForm();
		} else {
			mutateListCustomer(body);
		}
	};
	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				<Col xs="12" md="6">
					<div className="mb-2">
						<Label
							className="form-label mb-0 d-flex align-items-center"
							htmlFor="agent"
						>
							{t('assignTo')}
							<i
								className={`${infoIconClass} text-primary fs-6 ms-1`}
								id="assign-description"
							/>
							<TooltipDescription
								text={t('infoAssignation')}
								id="assign-description"
							/>
						</Label>
						<Select
							value={agents}
							isMulti
							onChange={(value) => {
								setAgents(value);
								formik.setFieldValue(
									'agents',
									value.map((it) => it.value)
								);
							}}
							options={agentsOpt}
							classNamePrefix="select2-selection"
							placeholder={tMessage(SELECT_OPTION)}
						/>
						{formik.errors.agents && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.agents}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col xs="12" md={4}>
					<div className="mb-2">
						<Label
							className="form-label mb-0 d-flex align-items-center"
							htmlFor="rangeDate"
						>
							{t('dateRange')}
						</Label>
						<div className="input-group">
							<DatePicker
								id="rangeDate"
								className="form-control"
								options={{
									mode: 'range',
								}}
								date={date}
								onChangeDate={(e) => {
									setDate(e);
									if (e.length === 2) {
										formik.setFieldValue(
											'dateRange.start',
											moment(e[0]).format('YYYY-MM-DD')
										);
										formik.setFieldValue(
											'dateRange.end',
											moment(e[1]).format('YYYY-MM-DD')
										);
									}
								}}
								placeholder={'DD/MM/YYYY a DD/MM/YYYY'}
								onClose={(selectedDates) => {
									if (selectedDates.length === 0) setDate([]);
								}}
							/>
							<div className="input-group-text bg-light text-dark">
								<i className="ri-calendar-2-line"></i>
							</div>
						</div>
						{formik.errors.dateRange?.end && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.dateRange?.end}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col xs="12" md="2">
					{isSearching ? (
						<div className="d-flex my-3">
							<ButtonsLoader
								buttons={[
									{
										text: t('search'),
										color: 'success',
										className: 'me-2',
										loader: true,
									},
								]}
							/>
						</div>
					) : (
						<div className="d-flex my-3">
							<Button
								type="button"
								color="success"
								className="me-2"
								onClick={search}
							>
								{t('search')}
							</Button>
						</div>
					)}
				</Col>
			</Row>
			<Row>
				<Col xs="12" md="6">
					<Accordion id="includes" flush>
						<AccordionItem>
							<h2 className="accordion-header" id="includes">
								<button
									className={classNames('accordion-button', {
										collapsed: !isInclude,
									})}
									type="button"
									onClick={() => setIsInclude(!isInclude)}
									style={{ cursor: 'pointer' }}
								>
									{t('include')}
								</button>
							</h2>
							<Collapse
								isOpen={isInclude}
								className="accordion-collapse"
								id="includes"
							>
								<div className="accordion-body">
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="callCenter"
										>
											{t('agent')}
										</Label>
										<Select
											value={includeObj.salesAgents}
											onChange={(value) => {
												formik.setFieldValue(
													'includes.salesAgents',
													value.map((it) => it.value)
												);
												setIncludeObj((prev) => ({
													...prev,
													salesAgents: value,
												}));
											}}
											isMulti
											options={agentsOpt}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
											isClearable
										/>
									</div>
									<div className="mb-2">
										<div className="d-flex">
											<div className="form-check me-3">
												<Input
													className="form-check-input"
													type="checkbox"
													id="includes.chargeBack"
													name="includes.chargeBack"
													checked={
														formik.values.includes
															.chargeBack
													}
													onChange={(evt) =>
														formik.setFieldValue(
															'includes.chargeBack',
															evt.target.checked
														)
													}
												/>
												<Label
													className="form-check-label"
													htmlFor="includes.chargeBack"
												>
													{t('chargeBack')}
												</Label>
											</div>
											<div className="form-check">
												<Input
													className="form-check-input"
													type="checkbox"
													id="includes.pendingBalance"
													name="includes.pendingBalance"
													checked={
														formik.values.includes
															.pendingBalance
													}
													onChange={(evt) =>
														formik.setFieldValue(
															'includes.pendingBalance',
															evt.target.checked
														)
													}
												/>
												<Label
													className="form-check-label"
													htmlFor="includes.pendingBalance"
												>
													{t('pendingBalance')}
												</Label>
											</div>
										</div>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="saleStatus"
										>
											{t('saleStatus')}
										</Label>
										<Select
											value={includeObj.salesStatus}
											onChange={(value) => {
												formik.setFieldValue(
													'includes.salesStatus',
													value.map((it) => it.value)
												);
												setIncludeObj((prev) => ({
													...prev,
													salesStatus: value,
												}));
											}}
											isMulti
											options={saleStatusOpt}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
											isClearable
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="noteStatus"
										>
											{t('noteStatus')}
										</Label>
										<Select
											value={includeObj.statusNotes}
											onChange={(value) => {
												formik.setFieldValue(
													'includes.statusNotes',
													value.map((it) => it.value)
												);
												setIncludeObj((prev) => ({
													...prev,
													statusNotes: value,
												}));
											}}
											isMulti
											options={emotionsClientsTypeOpt}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
											isClearable
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="campaing"
										>
											{t('campaing')}
										</Label>
										<SelectAsync
											fnFilter={getCampaingPaginate}
											query={'?page=1&max=10'}
											keyCompare={'name'}
											isClearable
											isMulti
											value={includeObj.campaings}
											onChange={(value) => {
												formik.setFieldValue(
													'includes.campaings',
													value.map((it) => it.value)
												);
												setIncludeObj((prev) => ({
													...prev,
													campaings: value,
												}));
											}}
											placeholder={tMessage(
												SELECT_OPTION
											)}
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="callCenters"
										>
											Call center
										</Label>
										<Select
											value={includeObj.callCenters}
											onChange={(value) => {
												formik.setFieldValue(
													'includes.callCenters',
													value.map((it) => it.value)
												);
												setIncludeObj((prev) => ({
													...prev,
													callCenters: value,
												}));
											}}
											isMulti
											isClearable
											options={callCenterOpt}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="countries"
										>
											{t('country')}
										</Label>
										<Select
											value={includeObj.countries}
											onChange={(value) => {
												formik.setFieldValue(
													'includes.countries',
													value.map((it) => it.value)
												);
												setIncludeObj((prev) => ({
													...prev,
													countries: value,
												}));
											}}
											isMulti
											isClearable
											options={Country.getAllCountries().map(
												(it) => ({
													label: it.name,
													value: it.isoCode,
												})
											)}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="includes.lastNoteCreated"
										>
											Última nota creada
										</Label>
										<Input
											type="number"
											id="includes.lastNoteCreated"
											name="includes.lastNoteCreated"
											checked={
												formik.values.includes
													.lastNoteCreated
											}
											onChange={(evt) =>
												formik.setFieldValue(
													'includes.lastNoteCreated',
													evt.target.value
												)
											}
										/>
										{formik.errors.includes
											?.lastNoteCreated && (
											<FormFeedback
												type="invalid"
												className="d-block"
											>
												{
													formik.errors.includes
														?.lastNoteCreated
												}
											</FormFeedback>
										)}
									</div>
								</div>
							</Collapse>
						</AccordionItem>
					</Accordion>
				</Col>
				<Col xs="12" md="6">
					<Accordion id="excludes" flush>
						<AccordionItem>
							<h2 className="accordion-header" id="excludes">
								<button
									className={classNames('accordion-button', {
										collapsed: !isExclude,
									})}
									type="button"
									onClick={() => setIsExclude(!isExclude)}
									style={{ cursor: 'pointer' }}
								>
									{t('exclude')}
								</button>
							</h2>
							<Collapse
								isOpen={isExclude}
								className="accordion-collapse"
								id="excludes"
							>
								<div className="accordion-body">
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="excludes.callCenter"
										>
											{t('agent')}
										</Label>
										<Select
											value={excludeObj.salesAgents}
											onChange={(value) => {
												formik.setFieldValue(
													'excludes.salesAgents',
													value.map((it) => it.value)
												);
												setExcludeObj((prev) => ({
													...prev,
													salesAgents: value,
												}));
											}}
											isMulti
											options={agentsOpt}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
											isClearable
										/>
									</div>
									<div className="mb-2">
										<div className="d-flex">
											<div className="form-check me-3">
												<Input
													className="form-check-input"
													type="checkbox"
													id="excludes.chargeBack"
													name="excludes.chargeBack"
													checked={
														formik.values.excludes
															.chargeBack
													}
													onChange={(evt) =>
														formik.setFieldValue(
															'excludes.chargeBack',
															evt.target.checked
														)
													}
												/>
												<Label
													className="form-check-label"
													htmlFor="excludes.chargeBack"
												>
													{t('chargeBack')}
												</Label>
											</div>
											<div className="form-check">
												<Input
													className="form-check-input"
													type="checkbox"
													id="excludes.pendingBalance"
													name="excludes.pendingBalance"
													checked={
														formik.values.excludes
															.pendingBalance
													}
													onChange={(evt) =>
														formik.setFieldValue(
															'excludes.pendingBalance',
															evt.target.checked
														)
													}
												/>
												<Label
													className="form-check-label"
													htmlFor="excludes.pendingBalance"
												>
													{t('pendingBalance')}
												</Label>
											</div>
										</div>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="excludes.saleStatus"
										>
											{t('saleStatus')}
										</Label>
										<Select
											value={excludeObj.salesStatus}
											onChange={(value) => {
												formik.setFieldValue(
													'excludes.salesStatus',
													value.map((it) => it.value)
												);
												setExcludeObj((prev) => ({
													...prev,
													salesStatus: value,
												}));
											}}
											isMulti
											options={saleStatusOpt}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
											isClearable
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="excludes.noteStatus"
										>
											{t('noteStatus')}
										</Label>
										<Select
											value={excludeObj.statusNotes}
											onChange={(value) => {
												formik.setFieldValue(
													'excludes.statusNotes',
													value.map((it) => it.value)
												);
												setExcludeObj((prev) => ({
													...prev,
													statusNotes: value,
												}));
											}}
											isMulti
											options={emotionsClientsTypeOpt}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
											isClearable
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="excludes.campaing"
										>
											{t('campaing')}
										</Label>
										<SelectAsync
											fnFilter={getCampaingPaginate}
											query={'?page=1&max=10'}
											keyCompare={'name'}
											isClearable
											isMulti
											value={excludeObj.campaings}
											onChange={(value) => {
												formik.setFieldValue(
													'excludes.campaings',
													value.map((it) => it.value)
												);
												setExcludeObj((prev) => ({
													...prev,
													campaings: value,
												}));
											}}
											placeholder={tMessage(
												SELECT_OPTION
											)}
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="excludes.callCenters"
										>
											Call center
										</Label>
										<Select
											value={excludeObj.callCenters}
											onChange={(value) => {
												formik.setFieldValue(
													'excludes.callCenters',
													value.map((it) => it.value)
												);
												setExcludeObj((prev) => ({
													...prev,
													callCenters: value,
												}));
											}}
											isMulti
											isClearable
											options={callCenterOpt}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="excludes.countries"
										>
											{t('country')}
										</Label>
										<Select
											value={excludeObj.countries}
											onChange={(value) => {
												formik.setFieldValue(
													'excludes.countries',
													value.map((it) => it.value)
												);
												setExcludeObj((prev) => ({
													...prev,
													countries: value,
												}));
											}}
											isMulti
											isClearable
											options={Country.getAllCountries().map(
												(it) => ({
													label: it.name,
													value: it.isoCode,
												})
											)}
											classNamePrefix="select2-selection"
											placeholder={tMessage(
												SELECT_OPTION
											)}
										/>
									</div>
									<div className="mb-2">
										<Label
											className="form-label mb-0"
											htmlFor="excludes.lastNoteCreated"
										>
											Última nota creada
										</Label>
										<Input
											type="number"
											id="excludes.lastNoteCreated"
											name="excludes.lastNoteCreated"
											checked={
												formik.values.excludes
													.lastNoteCreated
											}
											onChange={(evt) =>
												formik.setFieldValue(
													'excludes.lastNoteCreated',
													evt.target.value
												)
											}
										/>
										{formik.errors.excludes
											?.lastNoteCreated && (
											<FormFeedback
												type="invalid"
												className="d-block"
											>
												{
													formik.errors.excludes
														?.lastNoteCreated
												}
											</FormFeedback>
										)}
									</div>
								</div>
							</Collapse>
						</AccordionItem>
					</Accordion>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col xs="12" md="12">
					<Row>
						<Col xs="12" md="12">
							{!isSearching ? (
								<TablePaginate
									columns={columns}
									data={itemsData ?? []}
									initialState={{
										pageSize: 10,
										pageIndex: 0,
									}}
								/>
							) : (
								<Loader />
							)}
						</Col>
					</Row>
					<Row>
						<Col xs={12} md={12}>
							{formik.errors.customers && (
								<FormFeedback
									type="invalid"
									className="d-block"
								>
									{formik.errors.customers}
								</FormFeedback>
							)}
						</Col>
					</Row>
					{isAssigning ? (
						<div className="d-flex my-3">
							<ButtonsLoader
								buttons={[
									{
										text: t('ok'),
										color: 'primary',
										className: 'me-2',
										loader: true,
									},
									{
										text: t('cancel'),
										color: 'danger',
										className: 'btn-soft-danger',
										loader: false,
									},
								]}
							/>
						</div>
					) : (
						<div className="d-flex my-3">
							<Button
								type="submit"
								color="primary"
								className="me-2"
							>
								{t('ok')}
							</Button>
							<Button
								type="button"
								color="danger"
								className="btn-soft-danger"
								onClick={toggleDialog ? toggleDialog : () => {}}
							>
								{t('cancel')}
							</Button>
						</div>
					)}
				</Col>
			</Row>
		</Form>
	);
};

export default FormClientAssignment;
