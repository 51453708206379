import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { DELETE_QUESTION, QUESTION_CONFIRMATION } from '../constants/messages';
import ButtonsLoader from '../Loader/ButtonsLoader';
import { useTranslation } from 'react-i18next';

export default function ConfirmationModal({
	handleMainAction,
	show,
	setShow,
	isProcessing,
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.confirmationModal',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const onCloseClick = () => {
		setShow(false);
	};

	return (
		<Modal
			isOpen={show}
			toggle={onCloseClick}
			centered={true}
			backdrop={'static'}
			keyboard={false}
			className="overflow-hidden"
		>
			<ModalBody className="py-3 px-5">
				<Row>
					<Col lg={12}>
						<div className="text-center">
							<i
								className="mdi mdi-check-circle-outline text-success"
								style={{ fontSize: '9em' }}
							/>
							<h2>{tMessage(DELETE_QUESTION)}</h2>
							<h4>{tMessage(QUESTION_CONFIRMATION)}</h4>
						</div>
					</Col>
				</Row>
				<Row>
					<Col className="mt-3 d-flex justify-content-center">
						{isProcessing ? (
							<ButtonsLoader
								buttons={[
									{
										text: t('yesSendIt'),
										color: 'primary',
										className: 'btn-lg ms-2',
										loader: true,
									},
									{
										text: t('cancel'),
										color: 'light',
										className: 'btn-lg ms-2',
										loader: false,
									},
								]}
							/>
						) : (
							<div className="text-center mt-3">
								<button
									type="button"
									className="btn btn-primary btn-lg ms-2"
									onClick={handleMainAction}
								>
									{t('yesSendIt')}
								</button>
								<button
									type="button"
									className="btn btn-light btn-lg ms-2"
									onClick={onCloseClick}
								>
									{t('cancel')}
								</button>
							</div>
						)}
					</Col>
				</Row>
			</ModalBody>
		</Modal>
	);
}
